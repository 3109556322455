import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from "gatsby";
import { theme } from "../../utils/global-styles/theme";

const DynamicBackgroundImage = ({ child, isDetailView, styles }) => (
  <StaticQuery
    query={graphql`
      {
        allBgImgs: allFile(filter: { absolutePath: { regex: "/help-bg/" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                id
                fluid(maxWidth: 1440) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const currentBgImage = data.allBgImgs.edges.filter(img =>
        isDetailView
          ? img.node.relativePath.includes(
              theme.colors.themeName
            ) && !img.node.relativePath.includes("leaf")
          : img.node.relativePath.includes(
              theme.colors.themeName
            ) && img.node.relativePath.includes("leaf")
      );

      const imageData = currentBgImage[0].node.childImageSharp.fluid;

      return (
        <BackgroundImage
          Tag="section"
          fluid={imageData}
          css={css`
            ${styles}
          `}
        >
          {child || null}
        </BackgroundImage>
      );
    }}
  ></StaticQuery>
);

DynamicBackgroundImage.propTypes = {
  child: PropTypes.element,
  isDetailView: PropTypes.bool,
  styles: PropTypes.object,
};

export default DynamicBackgroundImage;
