export const fontFaces = {
  headers: "Gellix, sans-serif;",
  paragraphs: "Gellix-Medium, serif;",
  help: "Gellix-Medium, serif;",
  inputPlaceholders: "Gellix-Medium, serif;",
  agStandardBold: "agStandardBold, sans-serif;",
  agDemiMd: "agDemiMd, sans-serif;",
};

export default fontFaces;
