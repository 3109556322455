import { css } from "@emotion/core";
import fontImport from "./_font-import";
import colors from "./_colors";
import reset from "./_reset";
import breakpoints from "./_breakpoints";
import margs from "./_margins";
import paddings from "./_paddings";
import fontFaces from "./_font-faces";
import helpers from "./_helpers";
import mixIns from "./_mixins";

function columnMargins({ start, end, outer }) {
  //for 1440px, grid 1200px
  const COLUMN_WIDTH = 60,
    GUTTER_WIDTH = 43.64;

  return {
    marginLeft:
      (start - 1) * (COLUMN_WIDTH + GUTTER_WIDTH) - (outer ? GUTTER_WIDTH : 0),
    marginRight:
      (12 - end) * (COLUMN_WIDTH + GUTTER_WIDTH) - (outer ? GUTTER_WIDTH : 0),
  };
}

export const theme = {
  colors,
  breakpoints,
  fontFaces,
  helpers,
  reset,
  mixIns,
  margs,
  fontImport,
  columnMargins,
  paddings,
};
