export const colors = {
 primary: "#00A0CC",
 secondary: "#AFE6E3",
 tertiary: "#004969",
 accent: "#b4b4b4",
 main: "#000000",
 background: "#F7F2EA",
 light: "#eeeeee",
 lightSeaGreen: "#A4E2C7",
 jungleGreen: "#007076",
 white: "#ffffff",
 black: "#000000",
 dkBlue: "#03364f",
 royalBlue: "#2a6eeb",
 dkGray: "#322B2B",
 themeName: "blue"
};

export default colors;
