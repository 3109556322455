import GellixBold from "../../fonts/Gellix-Bold.woff2";
import GellixBold2 from "../../fonts/Gellix-Bold.woff";
import GellixBold3 from "../../fonts/Gellix-Bold.ttf";
import GellixMedium from "../../fonts/Gellix-Medium.woff2";
import GellixMedium2 from "../../fonts/Gellix-Medium.woff";
import GellixMedium3 from "../../fonts/Gellix-Medium.ttf";

const fontImport = {
  gellixBold: `@font-face {
          font-family: "Gellix";
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: local("Gellix-Bold"),
            url(${GellixBold2}) format("woff2"),
            url(${GellixBold}) format("woff"),
            url(${GellixBold3}) format("ttf");
        }`,
  gellixMedium: `@font-face {
          font-family: "Gellix-Medium";
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: local("Gellix-Medium"),
            url(${GellixMedium2}) format("woff2"),
            url(${GellixMedium}) format("woff"),
            url(${GellixMedium3}) format("ttf");
        }`,
};

export default fontImport;
