const margs = {
  textSM: "margin-top: .75rem;",
  textMD: "margin-top: 1rem;",
  textXL: "margin-top: 1.5rem;",
  sectionSM: "margin-top: 3rem;",
  sectionMD: "margin-top: 4.5rem;",
  sectionLG: "margin-top: 5.5rem;",
  sectionXL: "margin-top: 6.5rem;",
  center: "margin: 0 auto;",
  mobileLR: "margin: 0 14px;",
  tabletUpLR: "margin: 0 25px",
  tabletLandscapeUpLR: "margin: 0 40px",
};

export default margs;
