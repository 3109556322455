import React from "react";
import DynamicBackgroundImage from "../components/shared/DynamicBackgroundImage";
import { css } from "@emotion/core";
import { theme } from "../utils/global-styles/theme";

const CreateAccountPage = () => {
  return (
    <div>
      <DynamicBackgroundImage
        isDetailView={false}
        styles={css`
          background-position: center center;
          background-size: cover;
          min-height: 80vh;
          background-color: ${theme.colors.white};
        `}
      />
    </div>
  );
};

export default CreateAccountPage;
