const breakpoints = {
  mobile: `599px`,
  tabletUp: `600px`,
  tabletLandscapeUp: `900px`,
  iPadPro: `1025px`,
  desktopUp: `1200px`,
  xlDesktopUp: `1800px`,
  helper1350: `1350px`,
  helper1100: `1100px`,
  helper750: `750px`,
  helper800: `800px`,
  helper1440: `1440px`,
};

export default breakpoints;
